const ORIENTATIONS = {
    PORTRAIT : "portrait",
    LANDSCAPE : "landscape"
}

const MAX_RESOLUTIONS = {
     LANDSCAPE : {
        width: 1440
    }, 
    PORTRAIT : {
        height: 1440
    }
}

const COLLECTIONS_BASE = "/photo_collections/"

const EXTENSIONS = {
    SF : COLLECTIONS_BASE + "sf_buildings",
    LA:  COLLECTIONS_BASE +"la",
    BRUSHY_PEAK :  COLLECTIONS_BASE + "brushy_peak",
    AMERICAN_GARAGE :  COLLECTIONS_BASE + "american_garage",
    SHORT_VISIT_OAHU:  COLLECTIONS_BASE + "short_visit_oahu",
    APT315 :  COLLECTIONS_BASE + "apt315",
    APT304:  COLLECTIONS_BASE + "apt304",
    GRANDMA_SARAH:  COLLECTIONS_BASE + "grandma_sarah",
    GPARENTS_SHOTS: COLLECTIONS_BASE + "gparents_shots",
    DOGPATCH: COLLECTIONS_BASE + "dogpatch",
    APT23L: COLLECTIONS_BASE + "apt23L",
    MARIN: COLLECTIONS_BASE + "in_marin",
    FREEWAYS: COLLECTIONS_BASE + "freeways",
    THAILAND: COLLECTIONS_BASE + "thailand",
    VIETNAM: COLLECTIONS_BASE + "vietnam",
    COLECTOMY: COLLECTIONS_BASE + "proctocolectomy",
    COWELL_PURISMA: COLLECTIONS_BASE + "cowell_purisma",
}

const BREAK = {
    name: "photo break",
    url : "",
    orientation: ORIENTATIONS.PORTRAIT
    }

const photo_items = [
    {
        redirect: false,
        link: EXTENSIONS.LA, 
        text: "Last Summer in Los Angeles"
    },
    {
        redirect: false,
        link: EXTENSIONS.SF, 
        text: "Some Buildings in San Francisco (work in progress)"
    },
    {
        redirect: false,
        link: EXTENSIONS.DOGPATCH, 
        text: "Dogpatch"
    },
    {
        redirect: false,
        link: EXTENSIONS.BRUSHY_PEAK, 
        text: "Brushy Peak National Reserve"
    },
    {
        redirect: false,
        link: EXTENSIONS.COWELL_PURISMA,
        text: "Cowell-Purisma Trail"
    },
    // {
    //     redirect: false,
    //     link: EXTENSIONS.MARIN,
    //     text: "In Marin"
    // },
    {
        redirect: false,
        link: EXTENSIONS.SHORT_VISIT_OAHU, 
        text: "Short Visit to Oahu"
    },
    {
        redirect: false,
        link: EXTENSIONS.VIETNAM, 
        text: "Short Visit to Vietnam [Hanoi & HCMC]"
    },
    // {
    //     redirect: false,
    //     link: EXTENSIONS.THAILAND, 
    //     text: "Short Visit to Thailand [Bangkok & Ao Nang]"
    // },
    {
        redirect: false,
        link: EXTENSIONS.APT23L, 
        text: "Apartment 23L (work in progress)"
    },
    {
        redirect: false,
        link: EXTENSIONS.APT315,
        text: "Apartment 315"
    },
    {
        redirect: false,
        link: EXTENSIONS.AMERICAN_GARAGE, 
        text: "An American Garage"
    },
    {
        redirect: false,
        link: EXTENSIONS.FREEWAYS, 
        text: "Freeways (work in progress)"
    },
    {
        redirect: false,
        link: EXTENSIONS.COLECTOMY, 
        text: "Recovery From A Proctocolectomy (work in progress)"  
    },
    // {
    //     redirect: false,
    //     link: EXTENSIONS.BIG_SUR, 
    //     text: "Several Visits to Big Sur"
    // },
    // {
    //     redirect: false,
    //     link: EXTENSIONS.SF_VIEWS, 
    //     text: "Views In San Francisco (work in progress)"
    // }, 

]

const inspo_items = [
    {
        redirect: false,
        link: EXTENSIONS.GRANDMA_SARAH, 
        text: "Some Photos Of Grandma Sarah"
    }, 
    {
        redirect: false,
        link: EXTENSIONS.GPARENTS_SHOTS, 
        text: "Grandpa & Grandma's Shots (Dad's Side)"
    },
    {
        redirect: true,
        link: "https://michaelseh.com/", 
        text: "Michael's Stuff"
    },
    {
        redirect: true,
        link: "https://open.spotify.com/playlist/5fMz4zWOWqgEuBiApu8YPn?si=f5ea12fc6b3d43c4", 
        text: "A Sampling Of The Music I Like"
    },
    
]

const dogpatch_itenms = [
    {
        name: "dogpatch_train",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85L-tri_T1mDWyGW3RYG2f1LxLTLAGsAJe4WRqMDorptA3ZEy0P6TgL2SGN3VtaLJOX7DWbNR3TcsQFPsKWIucPu9zJKRLeAABlgWba4taUp4nj2nc2",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "dogpatch_station_night",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84HdqbMg1wMCAb1B9rXKylk3UKvRUSkfQ2UYn0O8P42lEa-vTdLT-T06dua_IogHVnrb3tE9CtD0rQuDSS8tf9I0taRqshmRug_E751T5DmkFC8TUVC",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "defunt_factory",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87gVTbKib3T07lQlYyg5Rm2n7pUW0FdXW5gP2_AJ7BCjFOVU2MMdI5r03Ya74lXvaZfNDfMYjR_wS55F-Zf49dO4s-mH1r8ytrF5U3qWYpcE1iljv1b",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "building_near_rh",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87qR9GMBLYgxw539n2gLAYcgExGxF-oi3UYD79I2nttx7uKY5FGKbaVyrEOBA-KhkKqY_cvQSaVFBKUNRCJzGFL08y-tnfMPMlVntqC8jQwJuufnGlA",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "bus_seat",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87BHAUz91o714-UlXH_M5M8KI-1YHHfcbMCo6tq1lYOF-VJxtWXFt8PivmsQ8BtwttJUExdCDm_cjfN49DJ2cJ0LlW_ppLs-z0Id4ZekBOR12r35_1O",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "raccoon",
        url : "https://lh3.googleusercontent.com/pw/ABLVV8757SvsekW4yRDNHAa2nNhfsG2JSiHxxu4B2ogdEoyyBr5E6cjj-JQxAq-TpP27rxWhb0XowtL22TZ2xqdpDcWukdHueLZjou_qMlNYr96bKLqWm9gQ",
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const marin_items = [
    {
        name: "sf from marin",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85fDX7wkSwldEJ2HlByR6lke1fMwnRQeW2CCUUAWtDJh9l9DCKnqA_oP6264jju-H46loJEocirf4BILlykMUCcRhe7L0gttieNHSw55-g44T9jHdOx",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "people looking at sf from marin",
        url : "https://lh3.googleusercontent.com/pw/ABLVV868XIv6NEH_oJvTKMraww1rao9JUyE-q7JulNB0TpzuysSieBoBHqtoyNSiaLUcJe9_DAJ2yfobYZbWXCxjGiTy_L_IM7x5QWH_mQl6kSoOGAGzxbDy",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "crazy lighthouse",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84LxB-hajAkKBPxfesAuMW1yMg0XWKI2j6n1nJ8Vtlj6DV9nWpecNT_D1jwdL65uslDu8z5tIRuBPlgzQmQ5KOrTEtYB4Ooilf7SojDz4wi61bT9tvV",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "coast northwest",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84MI44drv7PNfNsmdsC4hCIafMffvME1lOAa2bkuGD3tF76lY1lAWQVObbSDje-ClEpIOEa1GxN1-Rw8DBu4e5zQMSHhEfdFFb1nMCJNfhZdy3We2Ft",
        orientation: ORIENTATIONS.PORTRAIT
    }, 
    {
        name: "tiny friends",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86cTMvI5uOrFJVAAbbIri1acV5viZTEDYgPA9ML8T2i6eVMgEAdQPEaLfSUFqVRjVp5MwMrVJQfrhTdRI_Q1IgdVEprqr-vLXYOiX5lc-ToFMcpw2fP",
        orientation: ORIENTATIONS.PORTRAIT
    }, 
    {
        name: "moody moon",
        url : "https://lh3.googleusercontent.com/pw/ABLVV873oH5LgXzNb1vzEjF4jjWjuT5nhHpoX7RoskFsJsWVslMJsfm12pQHy4z9L4aMxYttGu0-HE9ZUA9ZPd-fr3OxWpDnrwmUMt2MxSEOr7XwK4NZ_xW5",
        orientation: ORIENTATIONS.PORTRAIT
    }, 
]

const freeway_items = [
    {
        name: "freeway on the walk home",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85G1PG6w51z9QfK_SkUm-fwNP06gLWfGQTg1GFcJepnvVTEltxrH9RwAzS4CRn73QLuJZh-c8y8lVB6M4i3r5th56vXjceHSsoVO3p7GtWG4_n13DrP",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "hiding the baker and hamilton sign",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOZSJ3Qhe0SnhB_6FgNIR4itUQxSm24k_dYw0pdp6p4QOEp6cw4Lnn3t37XUuKUG_5_AWyJP32TKoQl2-w40wSP15tJc4ehBmgEEIimMMUevWpbiUMZ",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "tower peaking out from under the freeway",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87SKuF5eXFhEPZeGT2QlmoIvGfy0fQLfwgU6ke4WbQnS87sn2wtIUxFWdhsLy5xSOqn5FXjy8aHb5NAZruRdm_lFZndCKHuxmglDJK0mNRKrnRujeKA",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "wide freeway in dogpatch",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87exHzL3ZjSS3uYfNRxlf6n0pSa_Yj00sA_G8cIJg7dlWosn1TBWEwkkothXxiSgCqgzplH16WzRZZLBEhzSRDz9sAFqAwzniWCiEcT72mlDaoLlbjC",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "sutro hiding behind the graffitied freeway",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86TY_6wtbkoI-LKcd95xplEpgxHA3bHdU-MEg-ULnkfD6_Y8BHrQxcWjmmWAGzE-jJabC9qh7bCoE5FdSeRcM9qruX6K2FAOQ2gyM685lrZfgEaZCUx",
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const sf_photo_items = [
    {
        name: "red_door_embarcadero",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87EYT_HXOf1Ek0cp4O7AeSjf2PbHbvdUJ8Y9ti2aL_IvlKwYjS8GZ0dWSw6PFAWOOPOY5G1kMGDHE1j6neW6VG6rmXqaZyg8BxQH9ya_jlq8Y2nsQ5y",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "building_embarcadero",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86wlFWq0UL0OIJIJgTmKQuivmSZQpWhEfKidezt6wsrxa7A1gCiCDGYlUqxkusfmWXqfl56FNTlp3hCP1F5pcMyITQ_gCfyP8qu1qQeb3wScWBu0WBb",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "baybride_reflection",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85MKGDeQlMyIGDSIAR2xK9BhuadKCh_S4IE3crhEnKgMLAgfRkq1_suDqdmgXTlYWX1aTrCZ6vZ8IBAiUrte2fCgcae3ILpuHbpzYvIBmY9lykE7_yt",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "transamerican building again but better",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86KLAow2bmhDsl_wyLWrFknTeb84sP3jWxx-RIyUATawFPyAwnkb3Ibo3bAFdhomIBn9L-qHfiWST2rBAOOCSXucyBePXFrhutZqmREarQJXOl5cg8q",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "market from twin peaks",
        url : "https://lh3.googleusercontent.com/KdO9y1Dj7L2hLbON_Qpcm0wXYesF9Cbxa16jlqW_rNOJ7WqIdzwjmrBcilwhIJlSvcKoVEum2DhaOszTK1sUAe_pJOhrv82s8UEc0mISa1PrS97t72BIf4Z_UrDq5COLPFYbkeDqAhU",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "dog at church washington square park",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84g7dFxVXAaGOT0mM_y3yGbPtCk2dwVA1zDdhF4J4zsRNCcU1-OfJWEEOcZZgFI8K6V88bG4050cT-AKsMCnZbkEBga6JnV19XGLS3FwzZ2jr2S0qXB",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "russian hill maybe",
        url : "https://lh3.googleusercontent.com/V5pveK_z5r2RJJOBRIGMWNlPdD2Xv-z00MV4NV0uGmTr4nLE8jevjVcKDeuZEISYU5_ERMBkjb5s55b66SWIYp5Fe86EU9b-bVqVotSu2YghRf6xa-PEXeo0dkUbUIgbvn6UoMOxk1A",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "smartcar",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86DsGVeVXdwCjhE5bsYx9jPeP_ilvw5ECiXfNSA8OaXe2RmU15WDgeo2gA4_415Qpug_LjmiNLvgf-blm2nEqU1nf_IKTyKLlqs0vE5yK52IUGFRH1K",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "green house",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87399cAjPxCY027RFAElMLipqKwEQo-L2LrlUQY1ixuoiFCI9nVvEjFdjfGSOdmxrWQIugvauJsR1ze5fXnULOI1LcAzAaf9aqmBrHrs4Z4OSWgf-eS",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "tai-chi academy",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNW5UafZASw0PmC4Kout1RmeWodTyakeT3e1DhtA6q_ONdZ6jfULjHErwe19iHHe-3GduYOAzapMGfR2XGmwgUdDY38YVI1fTTV2m7u1yryCQytkDYU",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "curtain house",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOLWyY_zIOaiiphXe2OmfjmkchrffTOlBkwWwei4Uq4i3Vwv200Uqzx95zFCHA_vMeLVB-QsIAKNCZsHfFaktvk2sllEDBI-AW2uWV-XK78nb45vPI8",
        orientation: ORIENTATIONS.PORTRAIT
    }, 
    // {
    //     name: "kayak at lake thing",
    //     url : "https://lh3.googleusercontent.com/gdZ6K0n09Ef2LXSfWy2ExaJ3LgR0LVt7Rc0jxH6zhy7Yj0INDXQbykKHecmgRvsMUMsicUNcwPn2j5gpjsr8kEmSfVxLYvo5X1TgahAW9j92QRrMHuO2n1HGa0EofwijOLqvUqOOuoU",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "house boat! house boat!",
    //     url : "https://lh3.googleusercontent.com/pw/AJFCJaXhA-gYMjPeN7_r11iJCiJ15qU1drDnECzwlngB0SdMytXVMfa4CybvwKEFiTG-jq7hq4tvUC9zbkN8OsICUNh84Ze6dKasJGAp7GBH0RTL25sEcQBQ",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "arch art supplies",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczMgEortFZJHY4tVwdhBeuHNg0waYYY_prZNf6EHZDLcHiSuneE0A2iG_8YSx4zGEE-6WZtSNcg5lu3RezuFUtusI92salZbpqJwblPvBvDBUTLTKjol",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "blue building under the bayshore bypass",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczNjoRzNAJbzT_GGrBJ6faV2VhUEmUKFK2H37-Bx4XcXRAgoJRSHjmd0u3JIang2iZ2tCEGBW0NjzsZM8LFg7Euz4DW9xjntxirkk4waGlQa2gsSysZn",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },

    // {
    //     name: "pair of houses in front of sutro portrait",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczMZKx8vFo83IAdU5dJf6xJVzKvDJPwQ8z_aYXFdRrt0gcf2hL9UTlEKJJkSI7X4ibFv--SuCTPFUiHHhWWCExwhlTcOsb5MU-Tvb-MoPJV7XXwFHeS0",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "pair of houses in front of sutro not-portrait",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczM9fBWaaPMcgNeFNx8e6NkplLH2vHfdjuLyT5U-wdOqVEU7nh4Ti9-lz9OAfn0SoZXT3bqWMwqK8-6ZKowB3L5tj9_Y_Cw9uG8mQSy2-TrKMxjtVesE",
    //     orientation: ORIENTATIONS.LANDSCAPE
    // },


    // views of the buildings. Doesn't quite working with these.
    // {
    //     name: "an evergeen boat seen from alta vista park",
    //     url : "https://lh3.googleusercontent.com/pw/AIL4fc_dS5OYeuskikXEuD8hILgEUHqFJbsMkQrDuz2DKzeDiSTdoijjZWh4ieKHDMlyKFKu_uAMnWJLPSzJbL7HXvImIcIq9ewVTv7PDbTGtguGBtHwXhuS",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
]


const la_photo_items = [
    {
        name: "hollywood sign",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85qUZgJD9mR3szaztkcBJXtg2ZW0dzBJWSEV1Jz__hmTqsEjnM_R8CN15KVNazy50xadw7yZE3gmZialWKzRYcCZgsQP_6i35WHj9Yula3t_koE9cgU",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "lake hollywood",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85w3a9BiA4W98ucPj0aVngiPipbaG__bn8jA0CoEaYOL20jlkwU0gBMP1EfPzcV3rZRyBMt5E8lU54F15Aq9FRtiIMNaea3XnFKQat9LsaaOHXzzy8k",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "hollywood on the freeway by echo park",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85dPuGNX13pWohwwrbtQpROX9J98O5xrzh-duPyfJjZsNnH9CeRsUeq7yJugLh0aHPpWOBMKlwGpbF-MO3xzWx5f1fGysLggFtZ4fRI7DXB91-NcBvP",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // BREAK,
    {
        name: "ct-03",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87b4u_EAzVEK3v6b0j8NmaQjUwOjzQtcH2EDbJviVA72oxfPFby6BCYQ4LxdcjJk47RD6aC9ecCurANo5cjDHeOSeTL7Xt9tXXZOfB5-NygndbN19Ij",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "ct-09",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86-fwVXBQA77kf0nzyv3YtlGd-wbqpih_HWPiJNUDU8t-KaNDuFAYSjHUqvNHms-AUq7kmK7Oa47Qfijvt8v4S-95WJ2mj-wpDqurGbLDtRAGHfZiuC",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // {
    //     name: "demo2",
    //     url : "https://lh3.googleusercontent.com/ZlPwFikHFM1eMw2Wy2NIVWnWKp1PaW0pxhXtTpzgNJiErALm5ycNyPsSvNHZrVWWxaR2tpAoXt7mSMNOv22ueNsuNfGTHLiI1GyRDPO-JY7ZV3hEwr1J-CDEiYqGvCoRXJd1ug7FOz4",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // BREAK,
    // {
    //     name: "crest-5",
    //     url : "https://lh3.googleusercontent.com/1-t8exDnKlMhDvOCxk0PHZSJDBu4rniVeHvj3zahQebqezhTkq4wkgUGqMTW5TX8IR0aoj-sFC3vjVJTHoJ_ozX2Iir7-r7jHWRF9_G_s16TGmBZ9iTc39DAi_ZjVRQ8v70IrQrzVyM",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "dukmejion-08",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86HPrzBsaJhnSlCRWziFgXwt3agD69TFpFhdcbyZhi0EH_0aBZRkIwVSeT6d-GnLUU3KIrDP3XiP4Z57FpmZe1Raco918E9IORPS3RhSCQvfSXe5sJz",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "dukmejion-01",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86oDs-3Ivz3rIoftAwtGCtwV2_bWTgQ8oZeL9LecHlXvb4oXvXxB-o1Xh9m9cCd1TisfGgr2LpPuFaiC39PAA4MEuqzSdGee8ibTU8ZwQkCJ0-wEomT",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "dukmejion-05",
    //     url : "https://lh3.googleusercontent.com/k5EbNic9_YGQAXRsrZK1SpTcn0pANGn-DhLCb2iGNThQ8XR4lDI_vU831fYzXLwP_7DBznPTWMZ3RaTnj37d2ImUKkq2bwJ8FzYGGHWDKzyYkBKDzUmjTl70yHbZ7doz_uW1cauFYzM",
    //     orientation: ORIENTATIONS.LANDSCAPE
    // },
    // BREAK,
    {
        name: "film-stuff-14",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87zQu3KaSeS1HylKvsEi42y8dz07ITHiawKUmspmLByhx4O3Xdc5oYIU5za_HdCpG76dXHj7fThEm88w4x00AmDz7ie0lloASu2hAqZf4qaD0d9Q2OD",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "film-stuff-05",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84QO0pnK1v5zNmJGEUxjnQ6RtJHKwSHsIvzh2QxtTbSXPZWGPF5AGqhQa6oi0erskp4ACsiabk7qp00kNg-4n2ifLF1_LCVmG9lFwgIq57BCzHNhtYD",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // BREAK,
    {
        name: "echo-01",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84gILKrHOX1I2fCJ6tznR4Q6Zp9kE19ydBI_n46Qwr1o9b7qSFnrwPIeVTkQZP5jKyz33n-N9_brw4uf5J5IBbqycmTKrOMcq0R2gEpbqGhxD2O0Iuf",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "ct-13",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85SzSweryAvVBDOYTwTRTa7BWmTqZBe3n77-O7D6EYM8E6HSmLp_XwAO06o1zYskr-dGscKKfLU4SaVdw_f4jH_7GMM71chgBXsWRSY7oGqWI6q3BtM",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "ct-12",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87MSftESqWderY-UvIvMVvg6b-zuSgnbT8YfhsQFnk7VJl1UdjTk8q7CR_bqJ54Bj-80LwuzGBPuGSe4T4LExiDfADtlcYUji96JVKXeTfeacdx4uZ3",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // BREAK,
    // {
    //     name: "right-04",
    //     url : "https://lh3.googleusercontent.com/x4EJ4ZwBIBJloeN6gDaU6ZHVdSM0WYbMKSlD4uuQIqZ9BYk5Pexeo8Bd8GuPtI2qD9Oo-g3xaRQ42iTinE-p4hdRS85NG5zWa4s02l4X9AutUphHEa2ycG3qYtbLQDETNftqSKavtpI",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "right-22",
    //     url : "https://lh3.googleusercontent.com/xlfydzNqFjWolQoEFCablQ8Z4v32itEv49YiS-2aVpkNzfbzZhFXflGZsPAeoYknHKhfoG0LPxXiz5Qpx_ScHAvREzYyO3W1DRKYsLFVurbR9Jf-reKqdTE5Hj3YJU-inUAM7DrhQ8g",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "right-16 meta irony",
    //     url : "https://lh3.googleusercontent.com/WIz9Z7iWWvC52E4wAsuj5SF27s4dgxzmzInad5koGCdZdNGAoe0e3mYTU6dFRG_7tMGl7hvYRcIfG3BvT7FFSInCfNnrqpMVxyYSh1D2HcRuwe328W6BR0N0G5LlZSMUhLoxeImetNQ",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // BREAK,
    // {
    //     name: "just three photos from the deck 0",
    //     url : "https://lh3.googleusercontent.com/_jsamRy0me09ugvEOgDC6-FWaQxdQKGDFxNzYDtQ9XMs79Vu6MPTe6A6IU_4wAIp1K-pY1Kxj7WaS6OOu_PFGNK7d5LXkH3_H6YYxoJyRHb4E4XCxpl9x9-fXVmGtE3amnNTbjWmGGE",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "just three photos from the deck 1",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86LvDaMgoUKsayqU8ukZpkkSd-xmDEJ5SYo69zGKs7cBNe4eAOyIPGH8FqJ-ppICkKuzOyhkqjaM4EPH0YP2B-eWQ1GpPpOM8Wu9Y2CSL2XYezbbF6H",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "just three photos from the deck 2",
    //     url : "https://lh3.googleusercontent.com/eg_AbLNTZwIr43uERAH7AED7daewkvYMJd99gj5SlPoYsBl33EXG7X4uqGo79YimpxFbxuzf-YnDuabIXa1JUi_f2frdgM-0kLEKO5wG8eo5iEwLERGqFfmXZxgwBwmWdnwR6P8Bvew",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // BREAK,
    // BREAK,
    {
        name: "everton_park_biker",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86Y8-GQgQl2QWndLn5iHL1HJsMBObsIvfcm7Hy9JC_t8djCHsGdlsbqpmlHLAZW9Ev9HXJvNGw8j4_KaDOlyh6LXhyzNmPUOnBH7W6rxTIkWAokYWvE",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "michael fence",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85AoqvEL8BiQ9PNKvv_8yLwV_Skws1URbkFmTsfsuHBWQPir6vmVFGp9KJlkhkDmkJiDhpaKpRYFNSMcaAzWesioVyDmA1DJAwA8iw-2kVZHck2GrlP",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // BREAK,
    {
        name: "house_night_view_of_apartment",
        url : "https://lh3.googleusercontent.com/pw/ABLVV866xiYOI9WghYvyTAh6CWXsz_j3b89xlJGOK_yp9RU4_57Jy9QI-KuuIfWMwcNSGi30dTorU8_81f8hnEYxRkjzSmJdgXcavxjb-jCcXADCYrgk-0mE",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // {
    //     name: "house_night_5",
    //     url : "https://lh3.googleusercontent.com/B-2ST6hXPV6wpAgp7Z8wh4uoia-1e9AT7Kk4EwfcCU_7zJRnzoyw1xIZ1EG1Rh5ifYfdMRKHUoa-7ZvYL3RoO_j-imWWRh0qhDTvdmlEIQ6OD0wR2pagCe6wAF0qsHia71kVEsMVo6g",
    //     orientation: ORIENTATIONS.LANDSCAPE
    // },
    // BREAK,
    // {
    //     name: "stoic michael",
    //     url : "https://lh3.googleusercontent.com/VXEwVk43yYib5SUua6AUjxRKUc_SFwU5iemQgcOvP5NR2uD6j-qURZrBluXpKL9lA4AEFY2rewO4kB21bRE5tIa1dP5TuG34OP_2vf3WMijaLuJWoylMS_Zv7zxO4bY5O0OrN1Alqpc",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: " jack_20.8",
    //     url : "https://lh3.googleusercontent.com/4e7Nvwd7ddaXgRgcV_8qC4YLAksSLjidPb2-s3zGRRs1y_eqXKbe_ZCF5T2vKPCgt29__PEIFpXfClnlsItcaqb4g_fNM7634km5FYJKtsgwQwM2TmvvkE9qGDqyrGZON4qDJR4MGqQ",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "agri_group_shout",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86VfFza0WIGM4XsN1zChbWzCSgZuHeUL9Bn9xC9iNhPtIniHE98lYJgqX56zTQrxtiVSnHfPk8LMz8Q2zXFy9JLPhyCjxo-ii0eYIqldxS3lOPgQTQZ",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // {
    //     name: "agri_shadow_mask",
    //     url : "https://lh3.googleusercontent.com/pw/ABLVV856qn56BZnZRNZHWhfGCmY8l_AX4F61jfsAiT5_KrJ5wL7t4IDkRLIPjoICVvWXxZVUVRDcRFjXRA1ZgcQh48Lmjo3WkrKie7-KFM7tcaApMSxnLhFi",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // BREAK,
    // {
    //     name: "house_night_06",
    //     url : "https://lh3.googleusercontent.com/pw/ABLVV84f72L7-7Agkn7U5OyV7poufF2yHjIRQTvW4fCjts5XhwHQBf9Ph-FRNFpWo4za9H9Y-p9vynfiSc8tWSATquIfPiDmDYIpi9ZgMtuwXj-ogH34iUsY",
    //     orientation: ORIENTATIONS.LANDSCAPE
    // },
    {
        name: "no walking on hill",
        url : "https://lh3.googleusercontent.com/pw/ABLVV865_-Jsqs3TNC16ceYlUOmKaFY_mDbyDaXKNbBAuS32y1QDYKre8GTUAxRgm0Wc15MlfeWtXxy2U7b-IvI4khx7cwFOvpY8mmtt7O6sa-W66TnPgU-v",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "6th street",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84dnp0cmHqGdHL5Poq_VRNFpxOKXiGicswGeW_1WIPr2ROxrThYHQ_vu9oErhVbVH4OTiwXVspjGvR0SPcwGjWKrimkEgXuwph5hynEDlA9x7GGyGuV",
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const brushy_peak_items = [
    {
        name: "triangle lake little people",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86hpMyvT-FuoGvtoOfEDunTtCCURZkwDliuH9z5mgszBMSCXu_vGMmWMk7TMlFoI8_465xRyFXEImLWPV9CMxoyZUIB1ZFHm5mSihJUOoxzuwdODp4X",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "two people hanging by triangle lake",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85yPWTxX7Q15uWxp3D25gHg_SGnMStNP5lfIJUk_xiG2vtoHPCugMu8ZCBp1P9qXDNjZdhEkTS4SCMGI75VKQvD47lm2MAhB0gLDkleWZGqK6-M98NW",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name:"two windmills",
        url : "https://lh3.googleusercontent.com/pw/ABLVV854-Rs9MMWteb1vgLwASWPSq26ysW5pH5MTEAOBr8m3FcTGrJ4I_E6aMWPqPFIut5UxuFiQESatLM3HtF4KYsCttGYb0nKvOxcCkcaYhRFbpW7siT6w",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "two cow friends",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84NiDNm-O48lgwX5CmjLJDA6lBcCb7slI3XHHgsePBPYOUCZlhUjvG6EWig7WAEet-a0cJrf7Hz2xJLfIZy0TPNv783vddbBbFbd8BlG4I4cedVpxpR",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "four cow friends",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84LsNk0rsc5MQZxVYu-9MXPs3HIvH-LzdrQh_XjUIUKY36SPp-3QPmx7an0-j0TLZ4Dc6cEjkx6VPNS9Wmg_CF785z8xtOMmDg1Js50v8j2iRAIhNK3",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "three cow friends",
        url : "https://lh3.googleusercontent.com/pw/ABLVV85IBT8iKuBzzOlcL5lKnSR9f_YXcmlk-YHVZnVWMxEi3KTqpq02OP2HVADa4jRatC6rUSGNeF8Qioh4sd8O4yC9kIpUTnsQ8gfF3qO21JDNlqR6mmag",
        orientation: ORIENTATIONS.LANDSCAPE
    },
]

const american_garage_items = [
    {
        name: "paint closet",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87kuNICyF4_SQFWWB8VAYyD9U4Ufu9GaCUyvqD_mzEw3GcC5-xRN1Cmqm3ESlAvTzS2PpYKvXS0kFh7wNNAy__ytpLctG73iBrJ0GnDRZEWikq7tVv2",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "bench",
        url : `https://lh3.googleusercontent.com/pw/ABLVV84b6d5nH_JKyyQn1QouPF8VfpCwggWrQA7zITyKRCpm_7uauKI61XC8yIc2kR3WlflUFJ7tpcBP-_PQYZJ3Hp43me9pSk8cMe4DkkYTowtWUR7RHDKr`,
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "items haning in garage",
        url : `https://lh3.googleusercontent.com/pw/ABLVV86mD_yoF0kWe8qB56fk3fUw6JwniImU5X5I2T1YR83LgNUekIvn5UeC4x0xGs-xFxnKZlgzIuzgQm6M6HGqSNjb1gFW2888ZzEszxaGRIaDJRtgAOWN`,
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "racing_makeshift_cross",
        url : `https://lh3.googleusercontent.com/pw/ABLVV85QTkzIeS_5sE16NNSZezjE8gfBjsNBZlRL5g8AmSgPggo0aEZwfTClep12oSZXmFXhfsc9zIokwSq3ZpAXB5RL2pP3xkguDIzLj_Gzgtgzm2cu0_Yh`,
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const oahu_items = [
    {
        name: "mid centuery house maybe",
        url : "https://lh3.googleusercontent.com/pw/ABLVV853t-14yADY4KzPAg5oBiGBW3ktF2kJ5FdzS1FAsUJhcBcrnk1zJ1NFu8SRVNms-KLhPDV0-hQJj3oqR_VKaBupEj_m3uDyUnnuw0DelBO9PY976Nev",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "surfboard surfboard",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86K3Q0rl9P--wYuQ9CQtTRelq7nNsLx_-Ntc9RHy56tOwUc5vgkHVqENetyZj-dTifoQAJF0PmB6MD_bYtpupso2d0VXwMWHY-m9M0Ix52N9fMXXlzP",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "green foard surfboard",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86RKu-uLOHOaHmh1Nv-5FiS9iLHPP-ev9-MLM5-XwbuxXTZy_XEDnK9XZAmogLU5O76hRBD0Em_vPfa-WUIj8eFC30d7cShsmT5Cf200aSVQRPuc_eI",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "blue honda microtruck",
    //     url : "https://lh3.googleusercontent.com/pw/ADCreHfu8URrjGvyS3z63CQPOK-JAuAkfdDF3i1OGiPoHRRxrfZvZwTWexHFkyRfSyyRaAQS7DC0Ml_FeiyChdZJruubdImbIFzwkS6Ni9IWiCbmmYhv_Vys",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "beautiful mountain",
        url : "https://lh3.googleusercontent.com/pw/ABLVV864YT1_NqiW-KbnkOrgtUl7Cs7gwVn3Mb57QfzUCj9JSxhulva9BXVP0oQMhpU60UE2KQNT-QzyasE86_5oE7o_C396m6uwRL7j9H8MmVd-bmQ3xifA",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "small outcropping island",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86tNRk8Szhxmv18rGBS5MBxyJaZSSHREAFkakF9K8fd9llog8fjl6Uk6IlDrUTNfFKqZfaSV9THbYjsX1F2pEQ40aY_A7fk1RCy3bmaoX2H3agWhKO9",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "baskemtball",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84AchaEDhFVXtbwUIxamur3Xt1zsy81X0TWzWs-F3Ig7Q81Miqo5quWSWO5dyyoyUxD-OpBphFALwIoYLL19_YguVDwx3Kp5G5_jS_Soauwa3HdhNH3",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    // {
    //     name: "couple in sunset",
    //     url : "https://lh3.googleusercontent.com/pw/ADCreHeBafB7NV8yi2wgA_YNlHsXwgh1BpnHzAmD4RMff88vKaenYIoShsMd-Fw7fZs5IFAh_-FkPLFf1QpceBb8Do-sGmiG6eyHcCaG-LKj3nkFZ6OhhDb1",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "couple on vacation",
    //     url : "https://lh3.googleusercontent.com/pw/ADCreHcvR_ytCs1880gAMbtvd5nzZPlISPQ_MfcV_ykl9YpKAACVzUcN-iz7gVl5JMdfRQYtug_GH2CnmI5New5MOEJGUVo1dWoGG3McJ-N6lGMlM--G86st",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
]

const apt315_items = [
    {
        name: "fam portrait",
        url : "https://lh3.googleusercontent.com/pw/ABLVV874nl-ZxvpNREst5Z8VO_KVh_d05aX1a0J2BuKcjtaDz0jIkzYaTZ1gQ3qNeOn4GyIfZzXRvBbqec3memCtjkC-Jnvx9OIsK3zPGOzwAP_O_HU-YZ0m",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "trevor room",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87PgrGRe8ZlST9QVv_FUEchjbQ5rq8PIaq9uSxLNMeEOGclRXSgNMFU8utCy1B_0P3FZIr7fJSLoTPv9451GGwIjB4C-ZMlx3M8dMl2_moLb6GjPmrs",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "mantej room",
        url : "https://lh3.googleusercontent.com/pw/ABLVV87UYgMiQVSSu3od5BP04Bd31N93iG4dA8QzPFAbuRduBw9JmQmyBFjoss7P4tmwCbXdk-Rlcgof4NgeV0Ezvsxa2K37IBjEz3gXJg79yxnWXgawnZXL",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "my room",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86QEF-B-vbc1vH-JQybjdCNIQ50dEEwVlQA5pLx5PQH4r9h89hyQFyC8SelgO-rXgHU0jd3rYFGI7Amv8ed11mpNskGo9_nvys1KlZ5VuSgpQoCJIe7",
        orientation: ORIENTATIONS.LANDSCAPE
    },
]

const apt304_items = [
    {
        name: "forrest bed",
        url : "https://lh3.googleusercontent.com/AZJJIqU2bBr-pfnTT5t8VDDdGuLpwkNEzL94-VC6InNdp6Th14GHeHOW6S1hmbJ-NKfy_nBfZxYK0esNPkogdFEbkMma-vErK2gQbQ5Voc8_31umDmQo_1aRF1MxbRGNef_2PiqNOMI",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "dalia flower",
        url : "https://lh3.googleusercontent.com/qf-wsHuMRGzNJN_Fs18fbwt2NK8Pi6c643YYZ-ukAArWm52wCpwqh0lb0BlmC9dXr7r742AAisPGRHRF2svp4xz_A6OlOv9o5zxGUI6hYvu38SffVskfi46F4NI7yqp38NGICJ9DbUs",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "desktop",
        url : "https://lh3.googleusercontent.com/A4ljKlN88mhDGqmiBik2G3jKSnfw03Hog3U-ZxlEEPdnlcb512xb__cd4uHKxXe1KPkxKi4QbBSx6XOCE0Ek-BHT147JpNs3p9aHSVdelwmBz4TY5btl7BxuwiHUidi7Q4Pj3xCfO34",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "couch & belt",
        url : "https://lh3.googleusercontent.com/fG_zXavOipoePIG8TyC3MI8HiuWTU0_iNuMMAAr1T3-AEPO3zk1Cvfqs2Chpr1C6j1zPoctbqa9ObournpmxPdG8fiAeNwoVh-Llx5fzX1CtgYwcWqjYFXWrZfm-SHgqljv_9VMYtFQ",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "mushroom head guy",
        url : "https://lh3.googleusercontent.com/ovPaxkDQpwtN2rhzVxhOv7HRsHHBkz_g_0jyBKbum7Uk4t4HG64GhA4uTzhJVNwqJAdoxmrSVEebYCwXu9-s6iCrJfj4d6-uYhvvveEGq3H8sXcYwKjABZcuiSg4j8U5kKC4m5mZtAA",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "dream frat house",
        url : "https://lh3.googleusercontent.com/OKtwt5hhsMesUwlktWoz8PW4R5VZc-ay-5m0XsHGVBNMdx1Zr-oZ4wmDqgsAGONJD7G50AKDJUxef6YWimESl6XKaCWjCsI3eYy0chwwI2l3gSKHU3ILIOY7MEAf8Dr-jW6rNM_jwcs",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "very lonelyt window",
        url : "https://lh3.googleusercontent.com/5SKCi01edoPyKXuqnnVe02IBacEz7eRv1ASdsQJ-c-J4n9_YtXa7xmjeoIiabRJeuJwsUt4nshuUHvzB0UzpgiicJPyGZQ95vYMpnabT1rAnLkbXHrh5jkywf5uPaDl9ohYlO8zRTOs",
        orientation: ORIENTATIONS.LANDSCAPE
    },
]

const silverlake_items = [
    {
        name: "a hhill",
        url : `${process.env.PUBLIC_URL}/photos/silverlake/steep.jpg`,
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "windsor street",
        url : `${process.env.PUBLIC_URL}/photos/silverlake/winsor.jpg`,
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "goofy dogs",
        url : `${process.env.PUBLIC_URL}/photos/silverlake/dogs.jpg`,
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "eyeball",
        url : `${process.env.PUBLIC_URL}/photos/silverlake/eye.jpg`,
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "downtown from windsor street",
        url : `${process.env.PUBLIC_URL}/photos/silverlake/downtown.jpg`,
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "tall long street",
        url : `${process.env.PUBLIC_URL}/photos/silverlake/long_street.jpg`,
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const grandma_sarah_items = [
    {
        name: "Soup Tasting",
        url : "https://lh3.googleusercontent.com/pw/ADCreHe6cUJTzxN5CRRa_A_ZVrJ_FD-4DIvOnUsoWF78A5OrGL6p9yzDxyQ8OdKrQx_V27AIwUqxNE9G6pqhqBb9ziXLc1ip4_K10MSTzMI5cAfLe2PCUyj8",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "together in front of a beautiful building",
        url : "https://lh3.googleusercontent.com/pw/ADCreHciglLg4CIs2YyqNE1JG5yf_yASCgGDd_Kr5g8sYxQsDN2fcPdVfNhLibQ3SLgHuHbF7ORes-JOJ9CAEiLGv1iioxb7zQ8QiwY1dfc3403KkvqtB_AK",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "love floating in a mirror",
        url : "https://lh3.googleusercontent.com/pw/ADCreHe7fnWTRdTAhuAitkNQFRL0RhQVrPU4MTVQ9t1oeVpnUGZO4MLZr9kwu19qzQC9ZRGfJcTcQhXWvjWACSQjCW3LAq44d1jG41lwMoGEGl1CvT-uH9NW",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "their friends",
        url : "https://lh3.googleusercontent.com/pw/ADCreHf0k5f20yI3ajvnwsVLOS2p-2mvHoJW1kH90igywwlGj9Cqng6V2dAaQryuwbtLba1GDazhqbuRJ8SEWZMLvujYlUraoWb7GDvEhY_qUWz_Nto3MUXl",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "pearl necklace",
        url : "https://lh3.googleusercontent.com/pw/ADCreHcAV6df51GNZZk2P_5fRb8D2-kSDq2ZjKy9tJsMTQhNktePMG_SlUMp_pgQiX8m4MLe0I8cHgMJ-yC3btk63q6qJY2mmoTyGjHXdwsUPSCfPKNFiJCI",
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const gparents_shots_items = [
    {
        name: "soapbox racers",
        url : "https://lh3.googleusercontent.com/pw/ADCreHfW85hpVOJSlqM5Irl2yP13CMRZw7Cs-ym68YoxjuAjw7A07tGSylccmT0TfvKrcsGnK6HEmpLSqNXcgou2RhsbW4mPBzr06OXCg7GUgCs7gXVVaOtu",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "ettrick race",
        url : "https://lh3.googleusercontent.com/pw/ADCreHejaG4KJjNpgHJtYtMwxi2bJOJd2wgyBvPnKhACGqJ7XXDGb03syYsUA9YN3zmq0j5LVUH92wFsQD0xr7nTwP9KDgC_61lO35oTJEHQqvb93mxfPURz",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "assembly",
        url : "https://lh3.googleusercontent.com/pw/ADCreHfyP4AiZD2sCPl0WmlNo-4ErnKQpedgT2WyQRmqBF7bybTgmWN_3Y6Xl8LxhA35U2QlZgpoMlvFsNun6ze_RblqgLv1wwFIV58G7t35qfX3nJcGGcVQ",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "working on a car",
        url : "https://lh3.googleusercontent.com/pw/ADCreHdOBEcfCYQX8xgmCaBMtQX-mFq9SMOl0Qzjl_Wfaa41mMHHvnhU0kiPicTlU3o3wYotkebiTaMVt92cTsK5RSPQCo9PQkJxzM5xLS1DK17pZqLPAcWl",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "watering the garden",
        url : "https://lh3.googleusercontent.com/pw/ADCreHfjlP_XVOv_4HzDYUlMbNQSrM4lJbEJfKbpkWsydi4nnlZQzsQcENkd9FF-xJiNf_DA02IqPt62dHOeXY1ENZJw-asFRMuJqQlwNKC6l9Nt_gVP_yBl",
        orientation: ORIENTATIONS.LANDSCAPE
    },
]

const apt23l_items = [
    {
        name: "out my window",
        url : "https://lh3.googleusercontent.com/pw/ABLVV866p0XoyJ7NGaV2ecwcoNzq9EzYKYn0s4mk4m4ogH8VnOhiYfG9mtZGMWyJaz1RK7JwSXFgeSMW20OsVize0mhAUU332KEidG2Wz4K3wYCG7JM3jdeU",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "looking towards the bridge",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczM7uspL38K_lUf6cvy22tL2gbSDspRrXblgnEMrBLrkqDu_Q2pIfGM-EBTaUZ1kKWlifd3qIrRmvHCIfreXZo1_fG8MCIP5JeyxGO56fmvfUngXpw37",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "the couch from the loft",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczPyyUPhzmX_Izoox_oyhth5PP0v7bHbNi0LM7YBoQzkXt_-oEpzvW7YVU2YhjHswgxjT167MuPQx2EF8HsP7rG5-M38okJuyDEnHrQu5FfoLTO184z7",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "straight down from the loft",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczOLv3vFnRxKQobp4EHvbCMsh0SCMxZw1wQIb_ulBo9b8K0AoYzKLgeNtNZeGDXmX4S-PlacKunlCAbkhq8ECtUXG6x3enIbA-7wbHJ_qB0hxuGKcV4u",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "the hallways are very yellow colored",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOMUZIcS9Idhcy_w9tiT9bAYMHXPU-BLKdMLu3DYZO-RbfZ_NyresdkGIp8RQqT_J9ICu5BHXIX9Qr8Q9PsBBotDLyqvPt6lAzfHbXmit66FN1vfUlg",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "out the window to the right",
        url : "https://lh3.googleusercontent.com/pw/ABLVV86g5TyEllGXMVMBOIPgVmOXMMJIRngadY1emKwInIl7hV60Jhrknv-Umyrd-DTTmrdcdnkBw_lIoJ9lNLsMNWLGHZq-ZQa3J8nS-4gbY19rsSSoYavn",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "elevator lobby lamp",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNzLVbvxX1FhOzXfTNfL_vJywLAlHWCMb2gvLmy1erRFf4RdgY3bh15Wmk-Xsaylb1nh9xfsnwxRlqDqzgscBYaNfcJ47ivajEssLU65PXQFq3DOPmY",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "staircase",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczP8Aj3swv8jviU2nO2wOFC8MUkSRgYFSJSHCmo6F78-5MKtOCYjIWaGHNJpXz54XqGL9pjwk2f07bFDfKd7c3quqnkX8hYk3EIL7PWw4Pq7F9gf5jT0",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "out the front door to the right",
        url : "https://lh3.googleusercontent.com/pw/ABLVV857TUHYu76SK6XKfVhmLm_YoKsKNi1PWjcGGie9sViGulCE0ribNi79ha2H80VVRUc7m9Ei4MpsZ6HcNv4K1lsWluXzkipmmNSLteDw-cSNy1x6JjYw",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "out the front door",
        url : "https://lh3.googleusercontent.com/pw/ABLVV84OAFyLpyAHuVSYFlYtlJg7tVHU8CP6bsPMg6UkOyHyx_EZ2Z0-BlGz9a6ahbCFWGCQgig-zNZwZ1WhcbPvXjxIqhU_YdlKDg3IWbnuIXquWRfe1PW6",
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const thailand_items = [
    {
        name: "phallic rocks",
        url : "https://lh3.googleusercontent.com/pw/AP1GczM3JQ0cS8M6Q2kQ7ZetzdGtygGpWHptwRt6PKo2VveB4gcFtrg8YojKXv2loEnOdDc-TsZbDixbRdDMDNz38qcali3G6PjBD4tcCFgD7QPPY0YYhRqR",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "triangular rock",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOWGPGNdc_gGgdkrNVN7a0P_ZJNoyc2BQUuyYSZjrqbGYFpalZ6vNVk28BwcdzZW3P3JAxOXwqRhG9cIUPK-s05SjXgkZU7mV_7I3_pqesq3CzUmhr2",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "a cliche sunset",
        url : "https://lh3.googleusercontent.com/pw/AP1GczP3eer5KCH2u9pOHmjUrLPb0NQtRtDsag1oaIyrPb2YCtT24gIF9ZWSFAcVcaPOngvKejFSff7th7CcIdmSS3AFLyRAo41If_UdoHg9JXlpXuWmCept",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "architecture",
        url : "https://lh3.googleusercontent.com/pw/AP1GczPR3Sp4U88K6A-5UaHNcypMjuk-nTgRChTsUpv1V9z7B884LKc609zp4GP3MXK2vds0xPMSRMg_s3m5HHdrSyIdROSlV8ssZneaBfKlUxkpz2u9KCsm",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "its a building",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNtlPZFygFYiHp-Zdfir8fuhWl0e68DZpvL7YHsQZe8rZe1DMowUUW4yjUjw-TrOUGxEaWXEIgRI_-CGnrt64OhQzJx7_G0j_AWuZw0mB3BXrLh6CRx",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "yummy",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczPlrqLs_ZrUgjhXmfh5U9aXAn6980sseGRm-1GcJFhZW21bmOU3Ow5ZP-WkLmQIoPRJhzfEXqt-hU6vJUE5_qSozDxNnqwJKlkU0ixYSos2ajodY4cj",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "skywalk",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczPL7Pncvp0-QRnsEZB5xzIzhaEsi9g9y2oQDJqmFo-7TPIhmiQYh5_1HZssAC1zll_wr0_zHUd3cyo1KaXerB27b6pypN6fMo5wjtP0pA4G0STQ_shk",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    
    // {
    //     name: "a unique sunset",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczNEk70coSFnGF0I3v7ZU8cwyo2oorxScd7unRTWU-2aUk4ZtSDXrp9BVaIC2Ooy7Y5ZqXZyGwGqcxf7INS8Q06aHWSAL0O7Wp4mS5iesrBZybNcoDVb",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
]

const vietnam_items = [
    {
        name: "note coffee",
        url : "https://lh3.googleusercontent.com/pw/AP1GczMncLCNMlJRpBSN53Xca8qnUQ4w6eokQDJ0bTs8R5ErVEvyR-7QmMaCPlkgeGBuQszIgX55l0Jtwgz7NmdR6e3gs060o0Pvu1A6rqaxADHZDDD9rbu9",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "portrait of Ho Chi Minh",
        url : "https://lh3.googleusercontent.com/pw/AP1GczMBfYwTcPSj4B6Bikfw5apiH3r-Lmyjlh0F09GTbwseLNOlng92iK8iQgoy9ZVUX6RCgZuBmx7TcN8q23ICfy2TymTihpedUXkczALBf63wTIwT6W7i",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "pictures to remember",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOYtY7AVoJ4aF3hOvXsvtgqC_aMYO1jnAoyDc29bRhdhAR4X4eKtFhgcrRnpAFYhInfqIM4Cyepx2zgY_ChUPKKx9HOKqqp3f1gIYCh8TvAC0qqHX2T",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "church",
        url : "https://lh3.googleusercontent.com/pw/AP1GczMKiw7XMQsfI4NXlEvYZKGxJ_TEI1myTFPvzaE8svjLtryk_ukKcmA5QXZatUON6_N4brWexNXh-LFqGyQu_Q4_RrIOTcH6TahcwQduz3tLLf0doMAE",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "an atrium in independence palace",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczMDrcfI7oH_o2GpJ679RVKRHUpBPNE_4CFHc0G2ClAAJ9yCNOCKXMHsDBFwiYucMedcG6fYz3OL3cNN-9zgazFJqGRdVy6MLRhPpkl2f4Om-jOLxp6F",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "night market clothes",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczOhhdPviCp6U_YPvF4TTLFRNIAAJA4J9EgNnUmtKW5cQp09EACR_06exly8FSyuu686BoZG02JWQdecDJgOf3rBg7Mg1vipiNTA7SFx_urBrkzulQAj",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "on a moped riding past Hồ Tây",
        url : "https://lh3.googleusercontent.com/pw/AP1GczPwCwXf0F85nVbkXqKh_r3lMGQWs8pS25D1sHpW45jDmEHna_XKEvf_DU93u7Da9p7QCBvCIBn76F1gJ_xJB7FCBWVidGxeSRnm6eo8c9hi3ljVtrEd",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "on her boat in mekong delta",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOiftsgXXgobQ75S7WGesfkJj55vsrD6QZ2LtgE0QkckOosoOyc_sFzdw3uhFMnDUAmZ_4aQJCu71fNZVZAuFKH7ZTYqnYY04HCDUQ2RpF4e48ETywZ",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "on his boat in Hồ Tây",
        url : "https://lh3.googleusercontent.com/pw/AP1GczMWqICiaTNHlACPJMEwWLj0HkfOu4IlsyTfs8Jgg0LHubB1sI9seQFPTZ1o1l8ppSwrpt5FKOVUJFe74g-Dmq5h12Wpu0BueLk4kJrQMT3dCoxLY_mx",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "on his boat in Hồ Tây",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczMGUWF4ra6H84vks2aFN7BaWhQKnERVqxKBwPi4PdHeUTibGbD7HYmS6hxl3Ge7Np8vTxRah9FNW6pk-BAAwE5l2IKiO43DXlagYL3GP6L5DBmelwSv",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    {
        name: "tall building at night",
        url : "https://lh3.googleusercontent.com/pw/AP1GczO_mAhU4UpQTKCk_oMKGCXPKq870lh8gYCqTQacfVnY7gvSWathncSIQUKZjTaC5_uvbF7mGsd69MDsqRaTrsBl338u_6cYgINCpgdbVru-pZsbXHG-",
        orientation: ORIENTATIONS.PORTRAIT
    },
]

const total_colectomy = [
    {
        name: "arriving home for the final time",
        url : "https://lh3.googleusercontent.com/pw/AP1GczPHEs-a1OrnkzbATSb8gZ0SAaADCMadxVDU1Rf3xN1zmykq_tz0mHy_HNTKtwRM-ztZDyP5xvdWzW-YMKq8-w6s0h0VLAysKrleOhbCl7inC2b1dF2o",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "looking in the mirror",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNyFlkPSjUu1PWKuJ46FgDupdmXtTT_n7IZP_AX6LFp7UWfWpM-y-9K8HFlkc7FFnFIH2odULz28zQMw4hkUm_YcU2lf_ysw0bme19Vf8c6qs-50idD",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "looking out from my room",
        url : "https://lh3.googleusercontent.com/pw/AP1GczM9qTfLVDd9Z3Cb84yamD-HnaNVfAmVjBrCx2XKkdvvGYCFKME5RLBQj0DOTWIKuN0YAOUXG2j5EECdPEzHrXGSoZEoHYyw9lhm_Vd8quzOQKiMVtJ1",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "dealing with phantom shirts",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNE3QCzRvb6PGbVet6C6NHyYibTGi3u8bXyri0RdKi406ithCk6MV5XgHww0YTZzBcCN42hyuLKzuzx-Cr4ygib4zE3V1toDPvkkkU7WlEG3xncYj-L",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "working through my insatiable anxiety which appears when moving",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNa5lymLSxBS_pGb82k3KmDLbKkZKSWjCxLE4HTm2HQvahyy7mzSgO1U8AReie1pJjwRFdPnB8IwF_ORR2VQb5At_RXs2GzUC7PyxTkHHvIQSSN2vC4",
        orientation: ORIENTATIONS.PORTRAIT
    }
]

const cowell_purisma = [
    {
        name: "cows on the coast",
        url : "https://lh3.googleusercontent.com/pw/AP1GczMRO4_6eRRs8kQOHinnjaxNf0l1D2KJDcQWQDUlYOpJaGl_S7dAcpIxcN59i7-3sDrIswfVoh7cXtLOEUohbyv07VpRQIJkR59aUbOHU6YzQNa3dcuu",
        orientation: ORIENTATIONS.LANDSCAPE
    },
    {
        name: "outhouse down long row",
        url : "https://lh3.googleusercontent.com/pw/AP1GczPWbkM3cJbIZNDI3mD6ybnWjq8F-rnFFoC3RWF7tHllNfcVhBiJxq2TJBR8JicrV3AyNBJLrlWahNw37iMEghUfb2LDzSUBhcyxFX9_7wmdSFbLDdiz",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "bird",
        url : "https://lh3.googleusercontent.com/pw/AP1GczNkO9LuHt1TJqoc6TS-DMiFDGdCdIz6J5tjpjhE6diV_SK4HyKlqmO3tiMZ9Pp3v_SAhAMPJL1UkPn4CCksetCg9Qf4Xkht-l3QqNn4AbwRcsMyLYOn",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "shack in the field",
        url : "https://lh3.googleusercontent.com/pw/AP1GczOpX2jww0VkgRPh4azcjEQ6DAw7MCMLdj7T0-idhtL-v89yzNPMl7Rxw_exmLzyDCEb9finU1ZC2xeitl2e_KqTzwdj-A132pSperytQfTr0XcHPuni",
        orientation: ORIENTATIONS.PORTRAIT
    },

    {
        name: "hawk",
        url : "https://lh3.googleusercontent.com/pw/AP1GczP5rEIwT-b2ex0ZRZ1SY0b9W6TfZRsV2AoEVHEJSyMAIUb_wW72v9ZkeGp_ClvL0-gKIjp2Sqzw4HKXBKpt3EBS34kOJkR2HYs4wfl9qEYG54Og0wL1",
        orientation: ORIENTATIONS.PORTRAIT
    },
    {
        name: "shack on the coast",
        url : "https://lh3.googleusercontent.com/pw/AP1GczPbCdQO6ADs227CAVWP3WAaWTZT6qNwYdwdyIrmdRiKOTjPVHqFXTMnEhpiz2jMkgSbh-9cZEFoFIGR-HguRT7HblGnXlt1p15Y3aQGpapTWxAeHAoh",
        orientation: ORIENTATIONS.PORTRAIT
    },
    // {
    //     name: "house on the mountain",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczNhihsC706FfZJeXoJBFOgk-0zdYaL87kF5sTZ4kf2THu8Lo-TwIKR5IXrxM6Xrh1JtOX1-bbiv0zdp-p5VBipJZ6UN5Vx4xwnfj-Rfin2hrLnsPPTM",
    //     orientation: ORIENTATIONS.LANDSCAPE
    // },
    // {
    //     name: "coast northwest",
    //     url : "https://lh3.googleusercontent.com/pw/ABLVV84MI44drv7PNfNsmdsC4hCIafMffvME1lOAa2bkuGD3tF76lY1lAWQVObbSDje-ClEpIOEa1GxN1-Rw8DBu4e5zQMSHhEfdFFb1nMCJNfhZdy3We2Ft",
    //     orientation: ORIENTATIONS.PORTRAIT
    // }, 
    // {
    //     name: "crazy lighthouse",
    //     url : "https://lh3.googleusercontent.com/pw/ABLVV84LxB-hajAkKBPxfesAuMW1yMg0XWKI2j6n1nJ8Vtlj6DV9nWpecNT_D1jwdL65uslDu8z5tIRuBPlgzQmQ5KOrTEtYB4Ooilf7SojDz4wi61bT9tvV",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "chilling on the beach",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczMAwPjXZrm-B61u2FfyfH0WPfgofprWZfaP1Ogsh57rcY555Nr4CkYkzrQLxvxIfr2vHWNasoIHBOIwaFsmFKrtQuZJotlRtgxXvn1b-Yi5fME3MfIs",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "walking on the beach",
    //     url : "https://lh3.googleusercontent.com/pw/AP1GczO2nTP1PAS28_nW9b4nMFRSMn4mBt-ylWcqbCowaIhftisRgi57SjBveLLus-Igo1nxmDqrxCR8uBJlvi-eTNHcoFunYqeHO4UUBgtN0UCn-h5X4AAa",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "people looking at sf from marin",
    //     url : "https://lh3.googleusercontent.com/pw/ABLVV868XIv6NEH_oJvTKMraww1rao9JUyE-q7JulNB0TpzuysSieBoBHqtoyNSiaLUcJe9_DAJ2yfobYZbWXCxjGiTy_L_IM7x5QWH_mQl6kSoOGAGzxbDy",
    //     orientation: ORIENTATIONS.PORTRAIT
    // },
    // {
    //     name: "sf from marin",
    //     url : "https://lh3.googleusercontent.com/pw/ABLVV85fDX7wkSwldEJ2HlByR6lke1fMwnRQeW2CCUUAWtDJh9l9DCKnqA_oP6264jju-H46loJEocirf4BILlykMUCcRhe7L0gttieNHSw55-g44T9jHdOx",
    //     orientation: ORIENTATIONS.LANDSCAPE
    // },
]

export {MAX_RESOLUTIONS, EXTENSIONS, ORIENTATIONS, photo_items, inspo_items, freeway_items, sf_photo_items, la_photo_items, brushy_peak_items, american_garage_items, oahu_items, apt315_items, apt304_items, silverlake_items, grandma_sarah_items, gparents_shots_items, dogpatch_itenms, apt23l_items, marin_items, thailand_items, vietnam_items, total_colectomy, cowell_purisma};