import React from 'react';
import '../../styles/collectionsPage.css';
import '../../styles/homePage.css';


import CollectionsTitleBar from './CollectionsTitleBar';
import CollectionsBody2 from './CollectionsBody2';

export default function Collections({title, items}) {

    return (
        <div className="home-box"  >
            <meta name="referrer" content="no-referrer-when-downgrade" />
            <CollectionsTitleBar title={title}/>
            <CollectionsBody2 items={items} />
        </div>
    );
}