import React from 'react';
import '../../styles/homePage.css';
import CollectionsBar from '../home/HomeBar';
import {Link} from 'react-router-dom';

export default function CollectionsBody2({items}) {
    // maybe have a set of colors to go thoruhg?
    const color_choices = ["#5e3e26", "#856e56", "#cc9b66", "#968171", "#d19669", "#d4b79f"]

    // Random starting index to mix up how colors look when the page is loaded
    const color_choice_offset = 1; //Math.floor(Math.random() * color_choices.length);

    const getColor = (idx) => {
        const color =  color_choices[(idx + color_choice_offset) % color_choices.length]
        return color
    }

    return (
    <div className="body">
        {items.map((element, index) => {
            return (
                <Link to={element.link} className="links"> <CollectionsBar text={element.text} color={getColor(index)} dark="#91c8e6"/></Link>
            )
        })}
    </div>
    );
}