import React, { Component } from 'react';
import './App.css';

import Collections from './components/collections/Collections';
import {photo_items, inspo_items, EXTENSIONS, sf_photo_items, total_colectomy, la_photo_items, brushy_peak_items, american_garage_items, oahu_items, big_sur_items, apt315_items, apt304_items, silverlake_items, grandma_sarah_items, dogpatch_itenms, apt23l_items, marin_items, freeway_items, thailand_items, vietnam_items, cowell_purisma} from './data/data.js';

import { HashRouter, Route, Routes } from 'react-router-dom';
import PhotoDisplayV2 from './components/photodisplay/PhotoDisplayV2';


export default function App(props) {
    return (
      <HashRouter>
        <div>
            <Routes>
              {/* <Route path="/" element={<Home />} exact/> */}
              <Route path="/" element={<Collections items={photo_items} title={"Joshua Baum Photo Projects"} />} exact/>
              {/* <Route path="inspo" element={<Collections items={inspo_items} description={"Various Inspirations"} />} exact/> */}
              {/* photo collectiosn urls here  */}
              <Route path={EXTENSIONS.SF} element={<PhotoDisplayV2 items={sf_photo_items} description={"Some Buildings in San Francisco"}/>} exact/>
              <Route path={EXTENSIONS.MARIN} element={<PhotoDisplayV2 items={marin_items} description={"In Marin"}/>} exact/>
              <Route path={EXTENSIONS.FREEWAYS} element={<PhotoDisplayV2 items={freeway_items} description={"Freeways"}/>} exact/>
              <Route path={EXTENSIONS.APT23L} element={<PhotoDisplayV2 items={apt23l_items} description={"Apt 23L"}/>} exact/>
              <Route path={EXTENSIONS.DOGPATCH} element={<PhotoDisplayV2 items={dogpatch_itenms} description={"Dogpatch"}/>} exact/>
              <Route path={EXTENSIONS.BRUSHY_PEAK} element={<PhotoDisplayV2 items={brushy_peak_items} description={"Brushy Peak National Reserve"}/>} exact/>
              <Route path={EXTENSIONS.LA} element={<PhotoDisplayV2 items={la_photo_items} description={"Last Summer In Los Angeles"}/>} exact/>
              <Route path={EXTENSIONS.COLECTOMY} element={<PhotoDisplayV2 items={total_colectomy} description={"Physical & Mental Recovery From A Proctocolectomy"}/>} exact/>
              <Route path={EXTENSIONS.AMERICAN_GARAGE} element={<PhotoDisplayV2 items={american_garage_items} description={"An American Garage (the one I grew up with)"}/>} exact/>
              <Route path={EXTENSIONS.SHORT_VISIT_OAHU} element={<PhotoDisplayV2 items={oahu_items} description={"Took a Short Trip To Oahu With My Sister"}/>} exact/>
              <Route path={EXTENSIONS.APT315} element={<PhotoDisplayV2 items={apt315_items} description={"Lived in Apt 315 for a year. We got some of our security deposit back."}/>} exact/>
              <Route path={EXTENSIONS.THAILAND} element={<PhotoDisplayV2 items={thailand_items} description={"Short Trip To Thailand With Friends [Bangkok & Ao Nang]"}/>} exact/>
              <Route path={EXTENSIONS.VIETNAM} element={<PhotoDisplayV2 items={vietnam_items} description={"Short Trip To Vietnam With Friends [Hanoi & HCMC]"}/>} exact/>
              <Route path={EXTENSIONS.COWELL_PURISMA} element={<PhotoDisplayV2 items={cowell_purisma} description={"Cowell-Purisima Trail"}/>} exact/>

              {/* <Route path={EXTENSIONS.APT304} element={<PhotoDisplayV2 items={apt304_items} description={"Lived in apt 304 for a while"}/>} exact/> */}
              {/* <Route path={EXTENSIONS.SILVER_LAKE} element={<PhotoDisplayV2 items={silverlake_items} description={"Quick walk through Silver Lake"}/>} exact/> */}
              {/* <Route path={EXTENSIONS.GRANDMA_SARAH} element={<PhotoDisplayV2 items={grandma_sarah_items} description={"Some wonderful photos of my late Grandma Sarah"}/>} exact/> */}
              {/* <Route path={EXTENSIONS.GPARENTS_SHOTS} element={<PhotoDisplayV2 items={gparents_shots_items} description={"Photos taken by my late Grandparents (dad's side)"}/>} exact/> */}
          </Routes>
        </div> 
      </HashRouter>
    );
}
